// Angular
import { Injectable } from '@angular/core';
// 3rd-party
import { BehaviorSubject, Observable } from 'rxjs';
// local
import { Office, UsersBrowseRequest, UserType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class OfficeStoreService {
  private usersBrowsRequestInitialValue: UsersBrowseRequest = {
    currentPage: 1,
    query: '',
    userType: UserType.ALL,
  };

  private _currentUsersBrowseRequest: BehaviorSubject<UsersBrowseRequest> = new BehaviorSubject<UsersBrowseRequest>(
    this.usersBrowsRequestInitialValue
  );

  private _currentOffice: BehaviorSubject<Office | null> = new BehaviorSubject<Office | null>(null);

  public readonly currentUsersBrowseRequest: Observable<UsersBrowseRequest> =
    this._currentUsersBrowseRequest.asObservable();

  public readonly currentOffice: Observable<Office | null> = this._currentOffice.asObservable();

  updateCurrentUsersBrowseRequest(usersBrowseRequest: UsersBrowseRequest): void {
    this._currentUsersBrowseRequest.next(usersBrowseRequest);
  }

  setOffice(office: Office) {
    this._currentOffice.next(office);
  }

  clearStore(): void {
    this._currentUsersBrowseRequest.next(this.usersBrowsRequestInitialValue);
    this._currentOffice.next(null);
  }
}
