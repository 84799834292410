import {Injectable} from '@angular/core';
import {TokenService} from "../token/token.service";
import {Role} from "../../models/role.enum";
import {UserStoreService} from "../user/user-store.service";

@Injectable({
  providedIn: 'root'
})
export class RoleCheckerService {

  constructor(
    private tokenService: TokenService,
    private userStore: UserStoreService,
  ) {
  }

  hasRole(role: Role): boolean {
    const roles = this.tokenService.getRoles();

    if (!roles) {
      return false;
    }
    return roles.includes(role);
  }

  hasAnyOfThese(roles: Role[]) {
    for (const role of roles) {
      if (this.hasRole(role)) {
        return true;
      }
    }

    return false;
  }

  hasOnlyRole(role: Role): boolean {
    const roles = this.tokenService.getRoles();

    if (!roles || roles.length !== 1) {
      return false;
    }

    return roles[0] === role;
  }

  isCourtUser(): boolean {
    return this.hasRole(Role.COURT_USER);
  }

  // A user with role OFFICE_ADMIN
  isOfficeAdmin(): boolean {
    return this.hasRole(Role.OFFICE_ADMIN);
  }

  // A user with role OFFICE_SUPERVISOR, but not OFFICE_ADMIN
  isOfficeSupervisor(): boolean {
    return this.hasRole(Role.OFFICE_SUPERVISOR) && !this.isOfficeAdmin();
  }

  // A user without roles OFFICE_ADMIN and OFFICE_SUPERVISOR
  isOfficeUser(): boolean {
    return this.hasRole(Role.OFFICE_USER) && !this.isOfficeAdmin() && !this.isOfficeSupervisor();
  }

  // extra rules

  // the roles a user can alter (toggle ON or OFF)
  // OFFICE_ADMIN cannot unset the OFFICE_ADMIN role for their own user
  canChangeRole(userId: string, role: Role,): boolean {
    // non office users can't change roles
    // office users can't change this for their own user
    return this.isOfficeAdmin() && userId !== this.userStore.getUserId() && (role === Role.OFFICE_SUPERVISOR || role === Role.OFFICE_ADMIN);
  }

  // default roles checked for creating a new user
  defaultRolesForNewUser(): Role[] {
    if (this.isCourtUser()) {
      return [Role.COURT_USER];
    } else {
      return [Role.OFFICE_USER]
    }
  }

  // if users can be viewed and managed
  canManageUsers(): boolean {
    return this.isOfficeAdmin() || this.isCourtUser();
  }

  canAddBankAccount(): boolean {
    return this.isOfficeSupervisor() || this.isOfficeAdmin() || this.isCourtUser();
  }

  canAuthorizeBankAccount(): boolean {
    return this.isOfficeSupervisor() || this.isOfficeAdmin() || this.isCourtUser();
  }

  canManageOffice(): boolean {
    return this.isOfficeAdmin() || this.isOfficeSupervisor() || this.isCourtUser();
  }

  canFilterAssignees(): boolean {
    return this.isOfficeAdmin() || this.isOfficeSupervisor();
  }

}
