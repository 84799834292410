// shared
import {
  Address,
  BankAccount,
  BankAccountOwnerType,
  BankAccountType,
  BrowseRequest,
  ClientType,
  LanguageCode,
  PagingResponse
} from 'src/app/shared';
import {SortRequest} from "src/app/shared/models/sort-request.model";
// configuration
import {JusticePeace, LabourCourt} from '../../configuration/models';

export interface ClientsResult extends PagingResponse<Client> {
  results: Client[];
}

export interface Client {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  ssn: string;
  address: Address;
  assigneeId: string;
  birthDate: string;
  languageCode: LanguageCode;

  unprocessedNotificationsCount: number;
  latestNotificationDate?: string;

  clientBankAccounts?: BankAccount[];
  officeBankAccounts?: BankAccount[];
  allClientBankAccounts: BankAccount[];
  allOfficeBankAccounts: BankAccount[];

  file: ClientFile;
  referralCode?: string;
  latestReferralCodeMailSentAt: string;
  referralCodeClaimed: boolean;
  creditors: Creditor[];
  reportSettings: ClientReportSettings;

  firstNamePartner?: string;
  lastNamePartner?: string;
  phonePartner?: string;
  emailPartner?: string;
  children?: string;

  archivedAt?: string;
  restorableUntil?: string;

  officeName?: string;

  repaymentData: CreditorPaymentInfo[];
}

export interface BankAccountIban {
  iban: string;
  type: BankAccountType;
}

export interface ClientFile {
  additionalInfo: string;
  endDate: string;
  startDate: string;
  admissibilityDateSettlement: string;
  homologationDateSettlement: string;
  totalDebt: number;
  retainedAmount: number;
  justicePeace: JusticePeace;
  labourCourt: LabourCourt;
  monthlyExpectedIncome: number;
  monthlyExpectedExpenses: number;
  decisionDateSettlement: string;
  caseIdentifier: string;
  docketNumber: string;
  mainSum: number;
  interest: number;
  costs: number;
  settlementTypeInfo: SettlementTypeInfo;
  periodicPayments: PeriodicPayments;
  type: ClientType;
  courtCosts: number;
  increases: number;
  balanceThreshold: number;
}

export interface SettlementTypeInfo {
  type: SettlementType;
  amount: number;
  percentage: number;
}

export enum SettlementType {
  NONE = 'NONE',
  MONTHLY = 'MONTHLY',
  PERCENTAGE = 'PERCENTAGE',
  BALANCE = 'BALANCE',
  MORATORIUM = 'MORATORIUM',
}

export interface PeriodicPayments {
  periodicityMonths: number;
  paymentStartDate: string;
  paymentEndDate: string;
}

export interface Creditor {
  id?: string;
  name?: string;
  companyNumber?: string;
  address?: string;
  iban?: string;
  ibanInvalid?: boolean;
  totalDebt?: number;
  mainSum?: number;
  retainedAmount?: number;
  paymentByThirdParties?: number;
  periodicAmountToPay?: number;
  interest?: number;
  costs?: number;
  remittanceInformation?: string;
  remittanceInformationInvalid?: boolean;
  remittanceInformationType?: RemittanceInformationType;
  position: number;
  courtCosts?: number;
  increases?: number;
}

export interface CreditorPaymentInfo {
  position?: number;
  name?: string;
  retainedAmount?: number;
  paymentByThirdParties?: number;
  remainingDebt?: number;
  paymentList?: PaymentInfo[];
}

export interface PaymentInfo {
  paymentDate?: string;
  paymentAmount?: number;
}

export enum RemittanceInformationType {
  STRUCTURED = 'STRUCTURED',
  UNSTRUCTURED = 'UNSTRUCTURED',
}

export interface ClientReportSettings {
  clientReportTypes: ClientReportType[];
  officeReportTypes: ClientReportType[];
}

export enum ClientReportType {
  FINANCIAL_REPORT = 'FINANCIAL_REPORT',
  YEARLY_DEBT_REPORT = 'YEARLY_DEBT_REPORT',
  YEARLY_OVERSIGHT_REPORT = 'YEARLY_OVERSIGHT_REPORT',
}

export interface ClientsBrowseRequest extends BrowseRequest, SortRequest {
  onlyUnprocessedNotifications?: boolean;
  clientType?: ClientType;
  office?: string;
}

export interface ClientReportsBrowseRequest extends BrowseRequest {}

export interface ClientReportsResult extends PagingResponse<ClientReport> {
  results: ClientReport[];
}

export interface ClientReport {
  id: string;
  createdAt: string;
  fileId: string;
  status: ReportStatus;
  title: string;
  type: ReportType;
  fileType: FileType;
}

export enum ReportStatus {
  DONE = 'DONE',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
}

export interface ClientDetailDTO {
  firstName: string;
  lastName: string;
  address: Address;
  phone: string;
  email: string;
  ssn: string;
  languageCode: LanguageCode;
  file: ClientFile;
  clientBankAccountIBANs: string[];
  bankAccountIds: string[];
  firstNamePartner: string;
  lastNamePartner: string;
  phonePartner: string;
  children: string;
  emailPartner: string;
  creditors: Creditor[];
}

export interface GenerateReportDTO {
  bankAccountIds: string[];
  periodFrom: string;
  periodTo: string;
  type: ReportType;
}

export interface ClientDocumentsBrowseRequest extends BrowseRequest {
  includeHiddenDocuments?: boolean;
  shared?: boolean;
  ownDocument?: boolean;
}

export interface ClientDocumentsResult extends PagingResponse<Document> {
  results: Document[];
  // filtered: Document[];
}

export interface Document {
  id: string;
  name: string;
  subTitle: string;
  date: string;
  hidden: boolean;
  ownDocument: boolean;
  shared: boolean;
}

export interface ClientCharacteristics {
  canHaveClientBankAccounts: boolean;
  canHaveOfficeBankAccounts: boolean;
  canHaveMultipleClientBankAccounts?: boolean;
  canHaveMultipleOfficeBankAccounts?: boolean;
  canHaveReimbursements: boolean;
  canHaveReports: boolean;
  canHaveDebts: boolean;
  canHaveBalanceAboveNotifications: boolean;
  canHaveIdentifiers: boolean;
  canHaveJusticePeace: boolean;
}

export interface AvailableReportTypes {
  reportTypes: AvailableReportType[];
}

export interface AvailableReportType {
  reportType: ReportType;
  label: string;
  disabled: boolean;
  disabledMessage: string;
  fileType: FileType;
}

export enum ReportType {
  FINANCIAL_REPORT = 'FINANCIAL_REPORT',
  YEARLY_DEBT_REPORT = 'YEARLY_DEBT_REPORT',
  YEARLY_OVERSIGHT_REPORT = 'YEARLY_OVERSIGHT_REPORT',
  FINANCIAL_MANAGEMENT_REPORT = 'FINANCIAL_MANAGEMENT_REPORT',
  YEARLY_REPORT_XLSX_GUARDIANSHIP = 'YEARLY_REPORT_XLSX_GUARDIANSHIP',
  FINAL_DEBT_REPORT = 'FINAL_DEBT_REPORT',
  HONORARIUM_GUARDIANSHIP = 'HONORARIUM_GUARDIANSHIP',
}

export enum FileType {
  PDF = 'pdf',
  DOCX = 'docx',
  XLSX = 'xlsx',
  XLSM = 'xlsm'
}

export interface ReportFormInfo {
  bankAccountInputAmount: BankAccountInputAmount;
  availableBankAccounts: BankAccountSelectItem[];
  periodInfo: PeriodFormInfo;
}

export enum BankAccountInputAmount {
  NONE = 'NONE',
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
  MULTIPLE_SPLIT = 'MULTIPLE_SPLIT',
}

export interface BankAccountSelectItem {
  id: string;
  iban: string;
  currency: string;
  itemLabel: string;
  ownerType: BankAccountOwnerType;
  position: number;
}

export interface PeriodFormInfo {
  supportedPeriods: SupportedPeriod[];
  defaultCustom?: DefaultPeriod;
  defaultMonthly?: DefaultPeriod;
  defaultQuarterly?: DefaultPeriod;
  defaultYearly?: DefaultPeriod;
}

export interface DefaultPeriod {
  periodFrom: string;
  periodTo: string;
}

export enum SupportedPeriod {
  CUSTOM = 'CUSTOM',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
  NONE = 'NONE'
}

export enum ClientStatus {
  ENABLED,
  RESTORABLE,
  ARCHIVED
}

export interface ClientReferral {
  code: string
}
